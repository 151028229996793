<template>
    <div
        class="columns"
        style="height: 100%;"
    >
        <div
            id="left-image"
            class="column is-two-thirds is-hidden-mobile"
        />
        <div
            id="login-container"
            class="column has-text-light is-paddingless"
        >
            <div class="columns is-centered is-mobile">
                <div class="column is-three-quarters">
                    <div
                        id="login-form"
                        class="is-flex"
                    >
                        <a
                            class="logo"
                            href="#"
                        >
                            <img
                                :src="logoWhiteLink"
                                :alt="configStore.config.appName"
                            >
                        </a>
                        <div>
                            <p class="is-size-4 has-text-white has-text-centered">
                                {{ $t('login.intro') }}
                            </p>
                            <p class="is-size-5 has-text-centered" />
                        </div>
                        <form
                            :method="currentSSOConfig ? 'GET' : 'POST'"
                            :action="currentSSOConfig ? currentSSOConfig.redirect_url : '/login'"
                            :aria-label="$t('login.login')"
                        >
                            <input
                                type="hidden"
                                name="_token"
                                :value="csrf"
                            >
                            <b-field
                                :label="$t('login.email')"
                                :type="emailError ? ' is-danger' : ''"
                                :message="emailError"
                                custom-class="has-text-white"
                            >
                                <b-input
                                    :placeholder="$t('login.email')"
                                    type="email"
                                    name="email"
                                    icon-pack="fas"
                                    icon="user"
                                    @update:modelValue="verifySSOMail"
                                />
                            </b-field>
                            <b-field
                                v-if="!currentSSOConfig"
                                custom-class="has-text-white"
                                :label="$t('login.password')"
                                :type="passwordError ? ' is-danger' : ''"
                                :message="passwordError"
                            >
                                <b-input
                                    type="password"
                                    name="password"
                                    icon-pack="fas"
                                    icon="lock"
                                    password-reveal
                                />
                            </b-field>
                            <div
                                v-if="!currentSSOConfig"
                                id="reset-password"
                                class=""
                            >
                                <a
                                    class="btn btn-link has-text-white"
                                    href="/password/reset"
                                >
                                    {{ $t('login.passwordReset') }}
                                </a>
                            </div>

                            <div class="field">
                                <div class="control">
                                    <button
                                        type="submit"
                                        class="button is-primary is-fullwidth"
                                        :class="{'mt-2': !!currentSSOConfig}"
                                    >
                                        {{ $t(`login.${currentSSOConfig ? 'continue' : 'loginSubmit'}`) }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { find, } from 'lodash'
import debounce from 'lodash/debounce'
import { mapStores, } from 'pinia'

import useConfigStore from '@/state/configStore.js'

export default {
    props: {
        csrf: {
            type: String,
            required: true,
        },
        emailError: {
            type: String,
            default: null,
        },
        passwordError: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            currentSSOConfig: null,
        }
    },
    computed: {
        ...mapStores(useConfigStore),
        logoWhiteLink () {
            return this.configStore.config.logoUrls.white
        },
    },
    methods: {
        // eslint-disable-next-line func-names
        verifySSOMail: debounce(function (email) {
            console.log(email)
            this.currentSSOConfig = find(this.configStore.config.sso, (ssoConfig) => {
                const regex = new RegExp(ssoConfig.email_regex)
                return email.match(regex)
            })
        }, 600),
    },
}
</script>
